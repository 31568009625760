@font-face {
  font-family: Meta;
  src: url("meta.28c58b40.28c58b40.eot");
  src: url("meta.28c58b40.28c58b40.eot#iefix") format("embedded-opentype"), url("meta.f2ad88df.f2ad88df.woff2") format("woff2"), url("meta.49c1144e.49c1144e.woff") format("woff"), url("meta.830059de.830059de.ttf") format("truetype"), url("meta.8ac0b1c4.fedb93cf.svg#Meta") format("svg");
}

@font-face {
  font-family: Meta;
  src: url("meta.28c58b40.eot");
  src: url("meta.28c58b40.eot#iefix") format("embedded-opentype"), url("meta.f2ad88df.woff2") format("woff2"), url("meta.49c1144e.woff") format("woff"), url("meta.830059de.ttf") format("truetype"), url("meta.fedb93cf.svg#Meta") format("svg");
}

/*# sourceMappingURL=index.0e8698f6.css.map */
